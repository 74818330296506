export const experienceData = [
    {
        id: 1,
        company: 'Sofusions Technologies',
        jobtitle: 'Frontend Development',
        startYear: '2012',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Sofusions Technologies',
        jobtitle: 'Backend Development',
        startYear: '2012',
        endYear: 'Present'
    },
    {
        id: 3,
        company: 'Freelance Developer',
        jobtitle: 'FullStack Developer',
        startYear: '2015',
        endYear: 'Present'
    },
]