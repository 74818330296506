export const educationData = [
    {
        id: 1,
        institution: 'JKUAT - Jomo Kenyatta University of Agriculture and Technology',
        course: 'Bsc. Information Technology',
        startYear: '2011',
        endYear: '2013'
    },
    // {
    //     id: 2,
    //     institution: 'Somerset School of Science',
    //     course: 'Bachelor of Technology',
    //     startYear: '2015',
    //     endYear: '2019'
    // },
    // {
    //     id: 3,
    //     institution: 'Hawking University, Cambridge',
    //     course: 'Master of Technology',
    //     startYear: '2019',
    //     endYear: 'Present'
    // },
]