import one from "../assets/svg/projects/one.svg"
import two from "../assets/svg/projects/two.svg"
import three from "../assets/svg/projects/three.svg"
import crypto from "../assets/png/portfolio-1-ConvertImage.png"
import netflix from "../assets/png/netflix.png"
export const projectsData = [
  {
    id: 1,
    projectName: "Netflix Clone",
    projectDesc:
      "Netflix Clone is a ReactJS application that is a clone of Netflix website. It is a single page application that is fully responsive and works on mobile devices.",
    tags: ["NextJS", "Tailwind", "Typescript", "Stripe", "Firebase"],
    code: "https://github.com/alphakex2/netflix-clone",
    demo: "https://netflix-clone-nine-opal.vercel.app/",
    image: netflix,
  },
  {
    id: 2,
    projectName: "Online Javascript Playground",
    projectDesc:
      "Write any javascript code on the browser i.e ES6,commonJs, React and you can also Import any library from npm on the fly without installing and the app will automatically fetch, cache and bundle in seconds. Zero configuration",
    tags: ["ReactJS", "TailWind", "Vite", "NestJS", "ESBuild"],
    code: "",
    demo: "https://www.alljs.rocks",
    image: one,
  },
  {
    id: 3,
    projectName: "EverythingCypto",
    projectDesc:
      "One Stop Shop for everything Cryptocurrency..Get marketshare info, existing currencies,latest news, perfomance history and many more",
    tags: [
      "ReactJS",
      "Tailwind CSS",
      "Redux toolkit",
      "Redux rtk",
      "Rapid Api",
    ],
    code: "https://github.com/alphakex2/everything-crypto",
    demo: "https://everything-crypto.netlify.app/",
    image: two,
  },
  {
    id: 4,
    projectName: "Design from figma to React.",
    projectDesc:
      "I decided to get a random free figma design and translate it into React, I focused on translating the design only",
    tags: ["ReactJS", "TailwindCSS", "Typescript"],
    code: "https://github.com/alphakex2/gocorona",
    demo: "https://figmareact.netlify.app/",
    image: three,
  },
  {
    id: 5,
    projectName: "A Slack Clone with video chat capabilities.",
    projectDesc:
      "I'll Post the link here after I'm done with implementing a few features and deployment.",
    tags: [
      "ReactJS",
      "GraphQL-HASURA",
      "Redux Toolkit",
      "Material UI",
      "Docker",
      "Apollo",
    ],
    code: "",
    demo: "",
    image: three,
  },
]

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
